<template>
  <div class="row">
    <div v-for="(data, key) in informes" :key="key" class="col-lg-6">
      <div
        class="bg-lighten w-100 br-20 mb-3 py-2 row align-items-center"
        style="min-height: 84px"
      >
        <div class="d-flex justify-content-center" style="width: 70px">
          <div @click="handlDownloadPdf(data._id)" class="bg-danger btn-icon">
            <i class="bi bi-file-richtext fs-1 text-white" />
          </div>
        </div>
        <div class="col">
          <div class="d-flex justify-content-between">
            <p class="text-danger fw-bolder">{{ data.nombre }}</p>
            <div class="d-flex p-2">
              <i
                @click="removeReporte(data._id)"
                class="bi bi-trash-fill mx-1 text-hover-danger cursor-pointer fs-3"
              />
              <i
                @click="
                  router.push({
                    name: 'profile-informes-form',
                    params: { _id: data._id },
                  })
                "
                class="bi bi-pencil-fill mx-1 text-hover-danger cursor-pointer fs-3"
              />
            </div>
          </div>
          <p class="text-card">
            Dispositivos vinculados: {{ data.dispositivos.length }}
          </p>
          <p class="text-card">Correos vinculados: {{ data.correos.length }}</p>
          <p class="text-card">Creado el: {{ data.created }}</p>
          <p class="text-card">Hora: {{ data.hora }}</p>
          <small class="text-info">{{ data.fechaInforme }}</small>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {
  getReportes,
  eliminarReporte,
  downloadReportePdf,
} from "@/services/informes";
import { pdf } from "@/services/generarBufferToPdf";
import moment from "moment-timezone";
import { alerta, confirmar } from "@/utils/mensajes";

export default defineComponent({
  name: "documents",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Documents", ["Pages", "Profile"]);
      fetchInformes();
      moment.locale("es");
    });
    const router = useRouter();
    const informes = ref([]);
    const fetchInformes = () => {
      getReportes()
        .then(({ data }) => {
          informes.value = data.map((e) => {
            let created = moment(e.createdAt)
              .tz("America/Bogota")
              .format("DD MMM Y | H:m");
            let fechaInforme = moment().format("dddd, MMM DD - h:m a");
            return { fechaInforme, created, ...e };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const removeReporte = (_id) => {
      confirmar().then(({ isConfirmed }) => {
        if (!isConfirmed) return;
        eliminarReporte(_id)
          .then(({ data, status }) => {
            if (status == 200) {
              alerta(
                "Mensaje",
                data.message ? data.message : "Eliminado correctamente",
                "success"
              );
              fetchInformes();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    };
    const handlDownloadPdf = (_id) => {
      downloadReportePdf(_id).then(({ data }) => {
        pdf(data);
      });
    };
    return {
      informes,
      router,
      removeReporte,
      handlDownloadPdf,
    };
  },
});
</script>
