import Swal from "sweetalert2";

export const alerta = (title = "Mensaje", message = "", icon = "info") => {
  Swal.fire(title, message, icon);
};

export const confirmar = (
  title = "seguro?",
  text = "No será posible rehacer esta gestión"
) =>
  Swal.fire({
    title,
    text,
    icon: "question",
    showCancelButton: true,
    focusConfirm: false,
    cancelButtonText: "Cancelar",
    confirmButtonText: "Si",
  });
