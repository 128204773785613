import { v4 as uuidv4 } from "uuid";
export const pdf = (blob, filename = null) => {
  filename = filename ? filename : `${uuidv4()}.pdf`;
  const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", filename); //any other extension
  document.body.appendChild(link);
  link.click();
  link.remove();
};
export default {
  pdf,
};
